import * as yup from 'yup';

export const validationSchemaAddBookmaker = yup.object().shape({
  name: yup.string().required('Nazwa bukmachera jest wymagana'),
  image: yup.mixed().required('Logotyp jest wymagany'),
  variants: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required('Wartość jest wymagana'),
        label: yup.string().required('Klucz jest wymagany'),
      }),
    )
    .required('Warianty sa wymagane')
    .min(1, 'Dodaj przynajmniej jeden wariant'),
});

export const validationSchemaAddCampaign = yup.object({
  name: yup.string().required('Nazwa kampanii jest wymagana'),
  endAt: yup.mixed().required('Data zakończenia jest wymagana'),
  refLink: yup.mixed().required('Reflink jest wymagany'),
  variant: yup.mixed().required('Wariant jest wymagany'),
  codes: yup.mixed().required('Dodanie kodu(ów) jest wymagane'),
});

export const validationSchemaLogin = yup.object({
  email: yup
    .string()
    .email('Niepoprawny email')
    .required('Email jest wymagany'),
  password: yup.string().required('Hasło jest wymagane'),
});

export const validationSchemaRegister = yup.object({
  name: yup.string().required('Imię i nazwisko jest wymagane'),
  phoneNumber: yup.string().required('Numer telefonu jest wymagany'),
  email: yup
    .string()
    .email('Nieprawidłowy format adresu e-mail')
    .required('Email jest wymagany'),
  agreement_1: yup.boolean().oneOf([true], 'Zgoda 1 jest wymagana'),
  agreement_2: yup.boolean().oneOf([true], 'Zgoda 2 jest wymagana'),
});

export const validationSchemaAddLayout = yup.object({
  name: yup.string().required('Nazwa layoutu jest wymagana'),
  component: yup.string().required('Nazwa komponent jest wymagany'),
});

export const validationSchemaDialogAddCodes = yup.object({
  codes: yup.mixed().required('Dodanie kodu(ów) jest wymagane'),
});
