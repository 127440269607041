import React, { useEffect, useState } from 'react';
import { BookmakerItemInterface } from '@components/BookmakerItem/interfaces';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import ButtonIcon from '@components/ButtonIcon';
import { formatDate } from '@utils/helpers';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import EditBookmaker from '@components/BookmakerItem/Edit';
import DeleteBookmaker from '@components/BookmakerItem/Delete';
import { doesCampaignExist } from '@utils/api/campaigns/doesCampaignExist';

const BookmakerItem: React.FC<BookmakerItemInterface> = ({
  image,
  name,
  createdAt,
  bookmakerRef,
  variants,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [campaignExist, setCampaignExist] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (bookmakerRef) {
        const result = await doesCampaignExist(bookmakerRef);
        setCampaignExist(result);
      }
    };

    fetchData().catch();
  }, [bookmakerRef]);

  return (
    <Card>
      <CardHeader
        avatar={<Avatar src={image} sx={{ width: 56, height: 56 }} />}
        action={
          <ButtonIcon
            icon="KeyboardArrowDown"
            onClick={() => setExpanded(!expanded)}
          />
        }
        title={name}
        subheader={createdAt && formatDate(createdAt, 'd MMMM, yyyy')}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            text="Warianty (klucz - wartość):"
            variant="caption"
            gutterBottom
          />
          <Box sx={{ display: 'flex', flexFlow: 'wrap column', gap: 1 }}>
            {(variants || []).map((item, index) => (
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                key={index}
              >
                <Typography text={index + 1 + '.'} variant="body2" />
                <Typography text={item.label} variant="body2" />
                <Typography text="-" variant="body2" />
                <Typography text={item.value} variant="body2" bold />
              </Box>
            ))}
          </Box>
        </CardContent>
        <CardActions
          disableSpacing
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <EditBookmaker bookmakerRef={bookmakerRef} />
          <DeleteBookmaker
            name={name}
            bookmakerRef={bookmakerRef}
            disabled={campaignExist}
          />
        </CardActions>
      </Collapse>
    </Card>
  );
};

export default BookmakerItem;
