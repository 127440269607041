import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@components/Button';
import { AdminPanelRoutes, Routes } from '@enums/routes';
import { verifiedPath } from '@utils/helpers';

const HorizontalMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isVerifications = verifiedPath(AdminPanelRoutes.VERIFICATIONS);
  const isRules = verifiedPath(AdminPanelRoutes.RULES);
  const isLayouts = location.pathname === Routes.SETTINGS;

  return (
    <Box sx={{ display: 'flex', flexFlow: 'nowrap row', gap: 2 }}>
      <ButtonGroup size="small">
        <Button
          size="small"
          text="Szablony"
          variant={isLayouts ? 'contained' : 'outlined'}
          onClick={() => navigate(Routes.SETTINGS)}
          color="info"
        />
        <Button
          size="small"
          text="Role"
          variant={isRules ? 'contained' : 'outlined'}
          onClick={() => navigate(AdminPanelRoutes.RULES)}
          color="info"
        />
        <Button
          size="small"
          text="Weryfikcja"
          variant={isVerifications ? 'contained' : 'outlined'}
          onClick={() => navigate(AdminPanelRoutes.VERIFICATIONS)}
          color="info"
        />
      </ButtonGroup>
    </Box>
  );
};

export default HorizontalMenu;
