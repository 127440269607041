export enum Collection {
  BOOKMAKERS = 'bookmakers',
  CAMPAIGNS = 'campaigns',
  CODES = 'codes',
  SETTINGS = 'settings',
  USERS = 'users',
  USER_BOOKMAKERS = 'userBookmakers',
  USER_CAMPAIGNS = 'userCampaigns',
  LAYOUTS = 'layouts',
}
