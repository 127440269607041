import React from 'react';
import { useDialogState } from '@store/useDialogState/useDialogState';
import Button from '@components/Button';
import Dialog from '@components/Dialog';
import Select from '@components/Select';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import { useSettingStore } from '@store/useSettingStore/useSettingStore';
import { useCampaignStore } from '@store/useCampaignStore/useCampaignStore';
import { generateURL } from '@utils/helpers';
import { getCampaignRef } from '@utils/api/campaigns/getCampaignRef';
import { DialogsID } from '@enums/dialogs';
import { LANGUAGE_OPTIONS } from '@enums/languages';

interface Props {
  campaignID: string;
}

const GenerateLink: React.FC<Props> = ({ campaignID }) => {
  const dialogId = DialogsID.GENERATE_LINK;
  const { setDialog, closeDialog, dialogs } = useDialogState();
  const { layouts } = useSettingStore();
  const { updateCampaign, getCampaign, loading } = useCampaignStore();

  const handleGenerateLink = () => {
    setDialog({ id: dialogId, title: 'Generator linku reklamowego' });
  };

  const formik = useFormik({
    initialValues: {
      language: '',
      template: '',
    },
    onSubmit: async (values, { resetForm }) => {
      const link = generateURL(
        `/${values.language}/${campaignID}/${values.template}`,
      );
      const campaignRef = getCampaignRef(campaignID);
      updateCampaign(campaignRef, {
        link,
        template: values.template,
        lang: values.language,
      });
      getCampaign(campaignRef);

      if (!loading) {
        resetForm();
        closeDialog();
      }
    },
  });

  return (
    <div>
      <Button text="Generuj link" onClick={handleGenerateLink} />

      {dialogs[dialogId]?.open && (
        <Dialog
          id={dialogId}
          width={500}
          closeAction={() => {
            closeDialog();
            formik.resetForm();
          }}
          confirmAction={formik.handleSubmit}
          closeName="Anuluj"
          confirmName="Generuj link"
          loading={loading}
          disabled={Boolean(!formik.values.template)}
        >
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'wrap column',
              gap: 2,
              paddingTop: 1,
            }}
          >
            <Select
              options={LANGUAGE_OPTIONS}
              name="language"
              value={formik.values.language}
              onChange={formik.handleChange}
              label="Wybierz język"
            />
            <Select
              options={layouts.map((layout) => ({
                value: layout.id,
                label: layout.name,
              }))}
              name="template"
              value={formik.values.template}
              onChange={formik.handleChange}
              label="Wybierz template"
              disabled={Boolean(!formik.values.language)}
            />
          </Box>
        </Dialog>
      )}
    </div>
  );
};

export default GenerateLink;
