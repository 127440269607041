import { Timestamp } from 'firebase/firestore';
import { format, isValid } from 'date-fns';
import { pl } from 'date-fns/locale';
import { CountryCode, isSupportedCountry } from 'libphonenumber-js';
import { functions, httpsCallable } from '@src/firebase-config';
import { useLocation } from 'react-router-dom';

export const getRandomQuote = () => {
  const randomNum = Math.floor(Math.random() * 8) + 1;
  return `quote${randomNum}`;
};

export const generateURL = (restLink: string) => {
  const originURL = window.location.origin;
  return originURL + restLink;
};

export const removeTrailingSlash = (value: string) => {
  let newValue = value;
  if (newValue.endsWith('/')) {
    newValue = newValue.slice(0, -1);
  }
  return newValue;
};

export const generateLink = (
  refLink: string,
  variant?: string,
  variantID?: string,
) => {
  let newRefLink = refLink;
  if (newRefLink.endsWith('/')) {
    newRefLink = newRefLink.slice(0, -1);
  }
  return `${newRefLink}/${variant}=${variantID}`;
};

export type FirestoreDate = { seconds: number; nanoseconds: number };

export const formatDate = (
  date: Date | FirestoreDate,
  dateFormat = 'dd-MM-yyyy',
  locale = pl,
): string => {
  let dateToFormat: Date;

  if ('seconds' in date && 'nanoseconds' in date) {
    const timestamp = new Timestamp(date.seconds, date.nanoseconds);
    dateToFormat = timestamp.toDate();
  } else {
    dateToFormat = date as Date;
  }

  return format(dateToFormat, dateFormat, { locale });
};

export const isDateBeforeNow = (date: Date | FirestoreDate): boolean => {
  let dateToCompare: Date;

  if ('seconds' in date && 'nanoseconds' in date) {
    dateToCompare = new Date(date.seconds * 1000 + date.nanoseconds / 1e6);
  } else {
    dateToCompare = date as Date;
  }

  const currentDate = new Date();
  return dateToCompare < currentDate;
};

export const parseTimestamp = (date: Timestamp, formatDate: string) => {
  if (date?.seconds) {
    const newDate = new Date(date.seconds * 1000);
    return isValid(newDate) ? format(newDate, formatDate) : '';
  }
  return '';
};

export const changeFormatDate = (date: string, formatDate: string) => {
  return format(date, formatDate, { locale: pl });
};

export const generatePassword = (length: number): string => {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@';
  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

export const isEmptyObject = (obj: Record<string, unknown>): boolean => {
  return Object.values(obj).every((value) => {
    if (typeof value === 'string') return value.trim() === '';
    if (Array.isArray(value)) return value.length === 0;
    if (typeof value === 'object' && value !== null)
      return Object.keys(value).length === 0;
    return false;
  });
};

export const getUserCountry = async (): Promise<CountryCode> => {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    const countryCode = data.country_code;

    if (isSupportedCountry(countryCode)) {
      return countryCode;
    }
    return 'PL';
  } catch (error) {
    return 'PL';
  }
};

export const getFunction = (functionName: string) => {
  return httpsCallable(functions, functionName);
};

export const parseCodes = (codes: string) => {
  return codes
    .split(';')
    .map((code) => code.trim())
    .filter((code) => code !== '');
};

export const verifiedPath = (path: string) => {
  const location = useLocation();
  return location.pathname.includes(path);
};
