import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CopyToClipboard from '@components/CopyToClipboard';
import CustomLink from '@components/CustomLink';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import TabPanel from '@components/TabPanel';
import Tabs from '@mui/material/Tabs';
import TitleSection from '@components/TitleSection';
import Typography from '@components/Typography';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { Status, StatusCode } from '@enums/status';
import { chipStatus } from '@utils/chipStatus';
import { changeFormatDate, formatDate, parseTimestamp } from '@utils/helpers';
import { getCampaignRef } from '@utils/api/campaigns/getCampaignRef';
import { useCampaignStore } from '@store/useCampaignStore/useCampaignStore';
import { useCodeStore } from '@store/useCodeStore/useCodeStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserCampaignStore } from '@store/useUserCampaignStore/useUserCampaignStore';
import GenerateLink from '@components/GenerateLink';
import DialogAddCodes from '@components/DialogAddCodes';
import { GridToolbar } from '@mui/x-data-grid';
import { DialogsID } from '@enums/dialogs';
import DialogEditCampaign from '@components/DialogEditCampaign';
import ButtonIcon from '@components/ButtonIcon';
import Divider from '@mui/material/Divider';
import DialogDeleteConfirm from '@components/DialogDeleteConfirm';
import { successMessages } from '@messages/successMessages';
import { useDialogState } from '@store/useDialogState/useDialogState';
import useNotifier from '@utils/useNotifier';
import { errorMessages } from '@messages/errorMessages';

export const ListCampaignItem = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { notify } = useNotifier();
  const { setDialog } = useDialogState();
  const { campaign, getCampaign, deleteCampaign } = useCampaignStore();
  const { codes, getCodesByCampaign, loading } = useCodeStore();
  const { userCampaigns, getUserCampaigns } = useUserCampaignStore();
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      if (params.campaignId) {
        const campaignRef = getCampaignRef(params.campaignId);

        getCampaign(campaignRef);
        await getCodesByCampaign(campaignRef);
        await getUserCampaigns(campaignRef);
      }
    };

    fetchData().catch();
  }, []);

  const handleBack = (path: string) => {
    navigate(path);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const codesAvailable = codes.filter(
    (code) => code.status === Status.AVAILABLE,
  );
  const codesUsed = codes.filter((code) => code.status === StatusCode.USED);
  const countUserCampaigns = userCampaigns.length;
  const countUserCampaignsWithCode = userCampaigns.filter(
    (item) => item.code != null,
  );
  const countUserCampaignsVerified = userCampaigns.filter(
    (item) => item.verified,
  );
  const countUserCampaignsPending = userCampaigns.filter(
    (item) => item.pending,
  );

  const handleDeleteCampaign = () => {
    if (params.campaignId) {
      const campaignRef = getCampaignRef(params.campaignId);
      setDialog({
        id: DialogsID.DELETE_CAMPAIGN,
        title: 'Usuń kampanie',
        text: 'Czy na pewno chcesz usunąć kampanię?',
        action: () => {
          if (userCampaigns.length > 0) {
            deleteCampaign(campaignRef).catch();
            handleBack(`/admin-panel/list-campaigns`);
            notify(successMessages.deleteCampaign, 'success');
          } else {
            notify(errorMessages.deleteCampaign, 'error');
          }
        },
      });
    }
  };

  const handleEditCampaign = () => {
    if (params.campaignId) {
      const campaignRef = getCampaignRef(params.campaignId);
      setDialog({
        id: DialogsID.EDIT_CAMPAIGN,
        title: 'Edytuj kampanie',
        params: { campaignRef },
      });
    }
  };

  return (
    <>
      <TitleSection
        text={`Kampania: ${campaign.name}`}
        onClick={() => handleBack(`/admin-panel/list-campaigns`)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ButtonIcon
            icon="Edit"
            onClick={handleEditCampaign}
            disabled={campaign.status === Status.INACTIVE}
          />
          <ButtonIcon
            icon="Delete"
            color="error"
            disabled={userCampaigns.length > 0}
            onClick={handleDeleteCampaign}
          />
          <Divider orientation="vertical" flexItem />
          <DialogAddCodes campaignID={params.campaignId} />
        </Box>
      </TitleSection>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card>
            <CardContent sx={{ padding: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography text="Status kampanii" variant="caption" />
                  <Box sx={{ width: '100%', marginTop: '4px' }}>
                    {chipStatus(campaign.status)}
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Typography text="ID kampanii" variant="caption" />
                  <Typography text={params.campaignId} variant="h6" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardContent sx={{ padding: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography text="Data utworzenia" variant="caption" />
                  <Typography
                    text={
                      campaign.createdAt
                        ? formatDate(campaign.createdAt, 'dd-MM-yyyy - HH:mm')
                        : '---'
                    }
                    variant="h6"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography text="Data zakończenia" variant="caption" />
                  <Typography
                    color="warning"
                    text={
                      campaign.endAt
                        ? formatDate(campaign.endAt, 'dd-MM-yyyy - HH:mm')
                        : '---'
                    }
                    variant="h6"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card>
            <CardContent sx={{ padding: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography text="Bierze udział" variant="caption" />
                  <Typography text={countUserCampaigns} variant="h5" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent sx={{ padding: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    text="Zweryfikowanych u bukmachera"
                    variant="caption"
                  />
                  <Typography
                    text={countUserCampaignsVerified.length}
                    variant="h5"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent sx={{ padding: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography text="Otrzymało kod" variant="caption" />
                  <Typography
                    text={countUserCampaignsWithCode.length}
                    variant="h5"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent sx={{ padding: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography text="Oczekujących na kod" variant="caption" />
                  <Typography
                    text={countUserCampaignsPending.length}
                    variant="h5"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent sx={{ padding: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Typography text="Reflink" variant="caption" />
                  <Typography text={campaign.refLink} variant="h6" />
                </Grid>
                <Grid item xs={2}>
                  <Typography text="Variant" variant="caption" />
                  <Typography text={campaign.variant} variant="h6" />
                </Grid>
                <Grid item xs={3}>
                  <Typography text="ID Template" variant="caption" />
                  <Typography text={campaign.template} variant="h6" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ opacity: campaign.status === 'active' ? 1 : 0.3 }}>
            <CardContent sx={{ padding: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography text="Link reklamowy" variant="caption" />
                  {campaign.link ? (
                    <Typography text={campaign.link} variant="h6" />
                  ) : (
                    <Typography
                      text="Link nie został jeszcze wygenerowany, przejdź do generatora linków."
                      variant="h6"
                    />
                  )}
                </Grid>
                {campaign.status === 'active' && (
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        height: '100%',
                        width: '100%',
                        gap: 1,
                      }}
                    >
                      <CopyToClipboard text={campaign.link} />
                      {params.campaignId && (
                        <GenerateLink campaignID={params.campaignId} />
                      )}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Tabs
              onChange={handleChange}
              value={value}
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab
                label={`Wszystkie kody (${codes.length})`}
                id="code-panel-1"
              />
              <Tab
                label={`Dostępne kody (${codesAvailable.length})`}
                id="code-panel-2"
              />
              <Tab
                label={`Użyte kody (${codesUsed.length})`}
                id="code-panel-3"
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              <DataGrid
                sx={{ border: 0, height: 400 }}
                disableColumnSelector
                disableColumnFilter
                disableRowSelectionOnClick
                loading={loading}
                columns={[
                  {
                    field: 'id',
                    headerName: 'ID',
                    flex: 1,
                    disableColumnMenu: true,
                  },
                  {
                    field: 'code',
                    headerName: 'Kod',
                    flex: 1,
                    disableColumnMenu: true,
                  },
                  {
                    field: 'status',
                    headerName: 'Status',
                    width: 140,
                    align: 'center',
                    headerAlign: 'center',
                    disableColumnMenu: true,
                    renderCell: (params) => chipStatus(params.value),
                  },
                ]}
                rows={codes}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DataGrid
                sx={{ border: 0, height: 400 }}
                disableColumnSelector
                disableColumnFilter
                disableRowSelectionOnClick
                disableDensitySelector
                loading={loading}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    csvOptions: {
                      fileName:
                        'Niewykorzystane kody dla kampanii - ' + campaign.name,
                      fields: ['code'],
                    },
                  },
                }}
                columns={[
                  {
                    field: 'id',
                    headerName: 'ID',
                    flex: 1,
                    disableColumnMenu: true,
                  },
                  {
                    field: 'code',
                    headerName: 'Kod',
                    flex: 1,
                    disableColumnMenu: true,
                  },
                  {
                    field: 'status',
                    headerName: 'Status',
                    width: 140,
                    align: 'center',
                    headerAlign: 'center',
                    disableColumnMenu: true,
                    renderCell: (params) => chipStatus(params.value),
                  },
                ]}
                rows={codesAvailable}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <DataGrid
                sx={{ border: 0, height: 400, backgroundColor: 'white' }}
                disableColumnSelector
                disableColumnFilter
                disableRowSelectionOnClick
                disableDensitySelector
                loading={loading}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    csvOptions: {
                      fileName: 'Użyte kody dla kampanii - ' + campaign.name,
                      fields: ['usedCodeDate', 'code', 'variantID'],
                    },
                  },
                }}
                columns={[
                  {
                    field: 'userRef',
                    headerName: 'Otrzymał',
                    width: 120,
                    renderCell: (params) => {
                      const id = `/admin-panel/list-users/` + params.value.id;
                      return <CustomLink to={id} label="Zobacz" />;
                    },
                    disableColumnMenu: true,
                  },
                  {
                    field: 'usedCodeDate',
                    headerName: 'Data użycia',
                    width: 210,
                    disableColumnMenu: true,
                    valueGetter: (value) =>
                      parseTimestamp(value, 'yyyy-MM-dd HH:mm'),
                    renderCell: (params) =>
                      changeFormatDate(params.value, 'd MMMM, yyyy - HH:mm'),
                  },
                  {
                    field: 'id',
                    headerName: 'ID',
                    flex: 1,
                    disableColumnMenu: true,
                  },
                  {
                    field: 'code',
                    headerName: 'Kod',
                    flex: 1,
                    disableColumnMenu: true,
                  },
                  {
                    field: 'status',
                    headerName: 'Status',
                    width: 140,
                    align: 'center',
                    headerAlign: 'center',
                    disableColumnMenu: true,
                    renderCell: (params) => chipStatus(params.value),
                  },
                  {
                    field: 'variantID',
                    headerName: 'Kod weryfikacyjny',
                    width: 160,
                    disableColumnMenu: true,
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (params) => {
                      if (!params.value) return null;

                      return (
                        <CopyToClipboard
                          text={params.value}
                          variant="outlined"
                          label="Kopiuj"
                          size="small"
                        />
                      );
                    },
                  },
                ]}
                rows={codesUsed}
              />
            </TabPanel>
          </Card>
        </Grid>
      </Grid>

      <DialogEditCampaign dialogId={DialogsID.EDIT_CAMPAIGN} />
      <DialogDeleteConfirm dialogId={DialogsID.DELETE_CAMPAIGN} />
    </>
  );
};
